











































































































































import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import CheckoutOrderSummaryComponent from '@/components/checkout/CheckoutOrderSummaryComponent.vue';
import CheckoutBillingInformationComponent
  from '@/components/checkout/CheckoutBillingInformationComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { BasicTypes } from '@/utils/types/BasicTypes';
import PackageInformation from '@/models/LocalStorage/PackageInformation';
import Order from '@/models/graphql/Order';
import { OrderFilter } from '@/graphql/_Filters/OrderFilter';
import {
  Appearance, loadStripe, Stripe, StripeElements,
} from '@stripe/stripe-js';
import ClientStorage from '@/utils/ClientStore';
import CookieService from '@/services/CookieService';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import OrderStatus from '@/utils/enums/OrderStatus';
import SalesPackage from '@/models/graphql/SalesPackage';
import PackageListComponent from '@/components/PackageListComponent.vue';
import StripeIntentStatusEnum from '@/utils/enums/StripeIntentStatusEnum';
import FormatHelper from '@/utils/helpers/FormatHelper';
import TypeOfPaymentEnum from '@/utils/enums/TypeOfPaymentEnum';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import WizardWidgetWrapper from '@/components/wrappers/WizardWidgetWrapper';
import WizardValidationRuleType from '@/utils/types/WizardValidationRuleType';
import WizardStepsEnum from '@/utils/enums/WizardStepsEnum';

const widgetDispatcherStore = namespace('WidgetDispatcherStore');
const salesPackageStore = namespace('SalesPackageStore');
const orderStore = namespace('OrderStore');
const localStorageCartStore = namespace('LocalStorageCartStore');

@Component({
  components: {
    FontAwesomeComponent,
    CheckoutOrderSummaryComponent,
    CheckoutBillingInformationComponent,
    ButtonComponent,
    ButtonIconComponent,
    PackageListComponent,
  },
})
export default class CheckoutWidget
  extends mixins(VueBaseWidget, VueRegisterStoreWidget, WizardWidgetWrapper) {
  @Prop({ required: false, default: null })
  private entityCode!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @widgetDispatcherStore.Getter
  private fetchMagicArgs!: Record<string, BasicTypes> ;

  @salesPackageStore.Action
  private getCartInformation!: (payload: {
    localStoragePackage: Array<PackageInformation>;
    companyUid: string;
  }) => Promise<boolean>;

  @orderStore.Action
  private getWithCompanyUid!: (payload: {filter: OrderFilter; companyUid: string}) => Promise<Order | undefined>

  @orderStore.Action
  private updateOrderStatus!: (payload: {uid: string; status: string }) => Promise<Order | undefined>

  @salesPackageStore.Getter
  private salesPackages!: SalesPackage[];

  @localStorageCartStore.Action
  private resetCart!: (cartUid: string) => void;

  @localStorageCartStore.Action
  private setCartWithData!: (payload: {cart: Order; cartUid: string}) => string;

  private stripeInformation = {
    publishableKey: '',
    clientSecret: '',
  }

  private stripePromise!: Promise<Stripe | null>;

  private stripe: Stripe | null = null;

  private stripeElements!: StripeElements;

  private order!: Order;

  private typeOfPaymentEnum = TypeOfPaymentEnum;

  private orderStatus = OrderStatus;

  private currentTypeOfPayment = this.typeOfPaymentEnum.CARD;

  private orderLoading = true;

  private isFormValid = false;

  private isPaying = false;

  private selectedLocale = ClientStorage.getItem('locale');

  private stripeError = false;

  private stripeErrorMessage = '';

  private get isCompanySet(): boolean {
    return !!(this.fetchMagicArgs.companyId);
  }

  private get isOrderUidSet(): boolean {
    return !!(this.fetchMagicArgs.orderId);
  }

  private get companyUid(): string {
    return this.fetchMagicArgs.companyId as string;
  }

  private get orderUid(): string {
    return this.fetchMagicArgs.orderId as string;
  }

  private get totalValue(): string {
    return FormatHelper.formatCurrency(
      (this.order && this.order.totalPrice) ? this.order.totalPrice / 100 : 0,
    );
  }

  private get isCreditCardEnable(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_CREDIT_CARD_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_CREDIT_CARD_FEATURE).enabled;
  }

  private get isBillMeLaterEnable(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_BILL_ME_LATER_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_BILL_ME_LATER_FEATURE).enabled;
  }

  private get bothisCreditAndIsBillEnable(): boolean {
    return this.isBillMeLaterEnable && this.isCreditCardEnable;
  }

  private get canPay(): boolean {
    return this.isFormValid;
  }

  private get needsApproval(): boolean {
    return this.salesPackages.some((obj: SalesPackage) => obj.needsApproval === true);
  }

  created(): void {
    if (this.isCreditCardEnable) {
      this.currentTypeOfPayment = this.typeOfPaymentEnum.CARD;
    } else if (this.isBillMeLaterEnable) {
      this.currentTypeOfPayment = this.typeOfPaymentEnum.BILL_ME_LATER;
    } else {
      this.$router.push({ path: '/' });
    }
    if (this.orderUid) {
      this.getWithCompanyUid({
        filter: {
          uid: this.orderUid,
        },
        companyUid: this.companyUid,
      }).then((order) => {
        if (order) {
          if (order.exhibitor?.uid !== this.companyUid) {
            this.$router.push({ path: '/' });
          } else {
            this.order = order;
            this.orderLoading = false;
            this.updateCartInformation();
            this.addValidator();
          }
        }
        this.setDataConfig();
      });
    }
  }

  mounted(): void{
    if (CookieService.getCookie(AUTH_TOKEN) === null) {
      this.$router.push({ path: '/' });
    } else {
      if (!this.isCreditCardEnable) return;
      const uploadUrl: string = process.env.VUE_APP_ACCOUNT_MANAGEMENT_BASE_URL ?? '';
      const stripeIntentUrl = `${uploadUrl}stripe/payment-intent-secret?orderUid=${this.orderUid}`;
      const request = new XMLHttpRequest();
      request.open('GET', stripeIntentUrl);
      request.setRequestHeader('jwt-token', CookieService.getCookie(AUTH_TOKEN) as string);
      request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
          const response = JSON.parse(request.responseText);
          if (response.publishable_key && response.client_secret) {
            this.stripeInformation.clientSecret = response.client_secret;
            this.stripeInformation.publishableKey = response.publishable_key;
            this.initiateStripe();
          } else {
            this.failSentry('Stripe get Key', 'Key were not fill');
            this.stripeError = true;
          }
        } else {
          this.failSentry('Request failed:', request.statusText);
        }
      };

      request.onerror = () => {
        this.failSentry('Stripe Connect for keys', 'Network error occurred');
      };
      request.send();
    }
  }

  validationRule(): WizardValidationRuleType {
    return (from?: number, to?: number, steps?: Array<UiPageWidget>) => {
      let stripeStatus = '';
      if (!steps || !to) return false;

      const stepPayload = JSON.parse(steps[to - 1].payload || '');
      const stepNavigationCode = stepPayload.navigationCode;
      switch (this.order.status) {
        case OrderStatus.PENDING:
        case OrderStatus.NEW_PENDING:
          stripeStatus = this.validateStripeStatus();
          if (stepNavigationCode !== WizardStepsEnum.CHECKOUT && stripeStatus !== StripeIntentStatusEnum.SUCCEEDED) {
            this.setStep(WizardStepsEnum.CHECKOUT);
            return false;
          }
          if (stepNavigationCode !== WizardStepsEnum.CONFIRMATION && stripeStatus === StripeIntentStatusEnum.SUCCEEDED) {
            this.resetCart(this.companyUid);
            this.setStep(WizardStepsEnum.CONFIRMATION);
            return false;
          }
          break;
        case OrderStatus.PROCESSING:
        case OrderStatus.PAID:
        case OrderStatus.REFUNDED:
        case OrderStatus.WAITING_FOR_APPROVAL:
        case OrderStatus.WAITING_FOR_PAYMENT:
          if (stepNavigationCode !== WizardStepsEnum.CONFIRMATION) {
            this.setStep(WizardStepsEnum.CONFIRMATION);
            this.resetCart(this.companyUid);
            return false;
          }

          break;
        default:
          this.$router.push({ name: 'company-cms-order', params: { companyId: this.companyUid } });
          break;
      }

      return true;
    };
  }

  private validateStripeStatus(): string {
    if (this.$route.query && this.$route.query.redirect_status) {
      if (this.order.paymentIntentId !== this.$route.query.payment_intent) {
        this.$router.push({ path: '/' });
      } else {
        return this.$route.query.redirect_status.toString();
      }
    }
    return '';
  }

  private initiateStripe(): void{
    const appearanceLocal: Appearance = {
      theme: 'stripe',

    };
    this.stripePromise = loadStripe(this.stripeInformation.publishableKey);
    this.stripePromise.then((stripe) => {
      if (stripe) {
        this.stripe = stripe;
        this.stripeElements = this.stripe.elements({
          clientSecret: this.stripeInformation.clientSecret,
          locale: (this.selectedLocale === 'en_US') ? 'en' : 'fr-CA',
          appearance: appearanceLocal,
        });
      }
    });
  }

  private updateCartInformation(): void {
    const list: Array<PackageInformation> = [];
    if (this.order.orderPackages) {
      this.order.orderPackages.forEach((orderPackage) => {
        if (this.order.exhibitor && orderPackage.salesPackage) {
          list.push({
            uid: orderPackage.salesPackage.uid,
            quantity: (orderPackage.quantity) ? orderPackage.quantity : 0,
          });
        }
      });
    }
    this.getCartInformation({
      localStoragePackage: list,
      companyUid: this.companyUid || '',
    });
  }

  private setElement(isValidForm: boolean): void{
    this.isFormValid = isValidForm;
  }

  private async pay(): Promise<void> {
    if (this.currentTypeOfPayment === this.typeOfPaymentEnum.CARD) {
      if (!this.isCreditCardEnable) return;
      if (!this.stripe) return;
      if (!this.stripeElements) return;
      this.isPaying = true;

      const paymentElement = this.stripeElements.getElement('payment');
      const optionsAddress = this.stripeElements.getElement('address');
      const linkAuthenticationElement = this.stripeElements.getElement('linkAuthentication');
      if (paymentElement && optionsAddress && linkAuthenticationElement) {
        paymentElement.update({ readOnly: true });
      }
      const urlPath = this.$router.resolve({
        name: 'company-cms-checkout',
        params: {
          companyId: this.companyUid,
          orderId: this.order.uid,
          step: 'confirmation',
        },
      }).href;
      const url = `${window.location.protocol}//${window.location.host}${urlPath}?step=${WizardStepsEnum.CONFIRMATION}`;
      const { error } = await this.stripe.confirmPayment({
        elements: this.stripeElements,
        confirmParams: {
          // eslint-disable-next-line max-len, @typescript-eslint/camelcase
          return_url: url,
        },
      });
      if (error) {
        this.failSentry('Stripe confirmPayment', error.charge);
        this.failSentry('Stripe confirmPayment', error.doc_url);
        this.stripeErrorMessage = (error?.message) ? error.message : '';
        this.stripeError = true;
        if (paymentElement && optionsAddress && linkAuthenticationElement) {
          paymentElement.update({ readOnly: false });
        }
        this.isPaying = false;
      } else {
        this.resetCart(this.companyUid);
        this.setStep(WizardStepsEnum.CONFIRMATION);
      }
    } else if (this.currentTypeOfPayment === this.typeOfPaymentEnum.BILL_ME_LATER) {
      this.isPaying = true;
      this.updateOrderStatus({
        uid: this.order.uid,
        status: OrderStatus.WAITING_FOR_PAYMENT,
      }).then((order) => {
        if (order) {
          this.order = order;
        }
        this.resetCart(this.companyUid);
        this.setStep(WizardStepsEnum.CONFIRMATION);
      });
    }
  }

  private goToCart(): void {
    this.setCartWithData({
      cart: this.order,
      cartUid: this.companyUid,
    });
    this.$router.push({
      name: 'company-cms-cart',
      params: {
        companyId: this.companyUid,
      },
    });
  }

  private failSentry(process = '', failMessage = ''): void {
    Sentry.withScope((scope) => {
      scope.setLevel(Severity.Error);
      Sentry.captureException(`Process: ${process} | Message: ${failMessage}`
        || `They was an error with stripe in the ${this.widget?.type} widget with id ${this.widget?.id}`);
    });
  }

  private setPaymentMethod(paymentMethod: TypeOfPaymentEnum): void{
    this.currentTypeOfPayment = paymentMethod;
  }

  private onRequestApproval(): void {
    this.updateOrderStatus({
      uid: this.order.uid,
      status: OrderStatus.WAITING_FOR_APPROVAL,
    }).then((order) => {
      if (order) {
        this.order = order;
      }
      this.resetCart(this.companyUid);
      this.setStep(WizardStepsEnum.CONFIRMATION);
    });
  }
}
